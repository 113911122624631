import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import { mq, colors } from "../utils/presets"
import SubpageTopimage from "../components/SubpageTopimage"
import parse from "html-react-parser"

const Title = styled.h1``

const Wrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  ${mq.tablet} {
    max-width: 1080px;
    margin: 0 auto;
    /* border-bottom: 3px solid ${colors.black}; */
    margin-bottom: 3rem;
  }
`

const Textwrapper = styled.div`
  flex: 1 0 auto;
  width: 100%;
  padding: 0 1rem;
  ${mq.tablet} {
    padding: 1rem 1rem 0;
    width: 35%;
    margin-right: 1rem;
    h1 {
      padding: 0;
      text-align: left;
      border-bottom: none;
      margin-bottom: 0;
      margin-right: 1rem;
    }
  }
  ${mq.tablet} {
    margin-right: 0;
    padding-right: 0;
  }
`

const JobsPageTemplate = ({ data }) => {
  const job = data.wordpressWpJobs

  const jobtype = job.acf.jobtype.value

  const description = parse(job.excerpt)

  const meta = description[0].props.children

  return (
    <Layout>
      <SEO
        title={job.title}
        description={meta}
        jobposting="true"
        modified={job.modified}
        jobtype={jobtype}
      />
      <SubpageTopimage
        objectFit="cover"
        objectPosition="50% 50%"
        fluid={job.featured_media.localFile.childImageSharp.fluid}
        alt={job.title}
      />
      <Wrapper>
        <Textwrapper>
          <Title
            dangerouslySetInnerHTML={{
              __html: job.title,
            }}
          />
          <div dangerouslySetInnerHTML={{ __html: job.content }} />
        </Textwrapper>
      </Wrapper>
    </Layout>
  )
}
export default JobsPageTemplate

export const query = graphql`
  query($id: Int!) {
    wordpressWpJobs(wordpress_id: { eq: $id }) {
      title
      excerpt
      content
      modified
      date
      acf {
        jobtype {
          value
        }
      }
      featured_media {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    }
    allWordpressWpJobs(sort: { order: DESC, fields: date }) {
      edges {
        node {
          title
          slug
          featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 90) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`
